'use strict';

import './utils/Polyfills';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { Router, Route, Redirect, IndexRoute, browserHistory, hashHistory, match } from 'react-router'
import uuid from 'uuid';
import cookie from 'cookie-monster';
import * as Sentry from "@sentry/react";

// initalize date localization
import moment from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
moment.locale('en');
momentLocalizer();

// Setup localstorage plugin
import store from 'store';
import storeExpire from 'store/plugins/expire';
store.addPlugin(storeExpire);

// Primary Pages
import Root from './Root.react';
import HomePage from './pages/HomePage.react';
import Dashboard from './pages/Dashboard.react';

// Recipe & Menus individual pages
import RecipeDetails from './pages/Recipes/Details.react';
import FoodDetails from './pages/Foods/Details.react';
import MenuCustomizer from './pages/Menus/Customizer.react';
import MenuViewer from './pages/Menus/Viewer.react';
import CollectionDetails from './pages/Collections/Details.react';

import AuthActions from './actions/AuthActions';
import ChunkLoadingActions from './actions/ChunkLoadingActions';
import AuthStore from './stores/AuthStore';
import ABTest from './utils/ABTest';
import Analytics from './utils/Analytics';
import { setConfig, cleanupLocalStorage, setMref, getMref, getConfig } from './utils/Env';
import { sentryBeforeSend } from './utils/Sentry';
import SessionRecorder from './utils/SessionRecorder';

const DEVICE_ID_COOKIE = 'device-id';

class App extends Component {
    static propTypes = {
        history_method: PropTypes.string,
    };

    static defaultProps = {
        history_method: 'browser',
    };

    static childContextTypes = {
        instance_id: PropTypes.string,
        launch_page: PropTypes.object,
        isChunkLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        if (process.env.NODE_ENV !== 'test') {
            //eslint-disable-next-line no-undef
            __webpack_public_path__ = props.asset_path + '/';
        } else {
            setConfig('users_api', props.fake_api_host);
            setConfig('recipe_api', props.fake_api_host);
        }

        const instance_id = uuid.v4();

        const device_id = store.get(DEVICE_ID_COOKIE) || cookie.getItem(DEVICE_ID_COOKIE) || uuid.v4();
        cookie.setItem(DEVICE_ID_COOKIE, device_id, {
            expires: moment().add(10, 'years').toDate().toUTCString(),
            domain: props.self_host.replace('www.', '.'),
            path: '/',
            secure: true,
        });
        store.set(DEVICE_ID_COOKIE, device_id);

        // Configure environmental configuration
        setConfig('instance_id', instance_id);
        setConfig('device_id', device_id);

        Object.keys(props).forEach((key) => {
            setConfig(key, props[key]);
        })

        setConfig('www_host', props.self_host.replace(/pro\./, 'www.'));
        setConfig('pro_host', props.self_host.replace(/www\./, 'pro.'));

        // Use local storage for storing authentication cookies in wrapped app
        if (window.cordova) {
            AuthStore.setStorage('local');
        }

        if (props.auth) {
            AuthStore.setStorage('memory');
            AuthStore.setToken('token', props.auth.token, moment(props.auth.token_expires));
            AuthStore.setToken('refresh', props.auth.refresh, moment(props.auth.refresh_expires));
        }

        AuthActions.setup();

        // Setup global error state handler
        window.onerror = function(msg, url, line, col, error) {
            try {
                Analytics.trackUncaughtException(error);
            } catch (exp) {
                // we threw an additional exception, ignore it, nothing we can do.
            }
        };

        // App doesn't have much state
        this.state = {
            instance_id,

            isChunkLoading: false,
        };

        const environment = getConfig('environment');
        const latestVersion = getConfig('build_number');
        const sentryDsn = getConfig('sentry_dsn');

        let history = browserHistory;

        // Did props tell  us to use a different history mechanism?
        switch(props.history_method) {
            case 'hash':
                history = hashHistory;
                break;
        }

        Sentry.addTracingExtensions();
        Sentry.init({
            dsn: sentryDsn,
            environment: environment,
            debug: false,
            release: latestVersion,
            integrations: [
                new Sentry.BrowserTracing({
                    tracePropagationTargets: [
                        /^https:\/\/(.*)\.eatlove.*$/,
                        /^https:\/\/(.*)\.chewba.*$/,
                        'localhost',
                        'capacitor://localhost',
                    ],
                    routingInstrumentation: Sentry.reactRouterV3Instrumentation(
                        history,
                        this.routes,
                        match
                    ),

                }),
                new Sentry.Integrations.GlobalHandlers({
                    onerror: true,
                    onunhandledrejection: true,
                }),
            ],
            // Set tracesSampleRate to capture transactions for performance monitoring.
            // tracesSampleRate: environment === 'local' ? 1.0 : 0.1,
            // sampleRate: 1.0,
            enableTracing: false,
            beforeSend: sentryBeforeSend
        });
    }

    getChildContext = () => {
        const { isChunkLoading } = this.state;

        return {
            instance_id: this.state.instance_id,
            launch_page: this.props.page,
            isChunkLoading,
        };
    }

    onPageUpdate = (args) => {
        $('body').removeClass('ReactModal__Body--open').removeAttr('aria-hidden');
    }

    componentDidMount = () => {
        global.setIsChunkLoading = ChunkLoadingActions.setIsChunkLoading;
    }

    componentWillUnmount = () => {
        delete global.setIsChunkLoading;
    }

    lastRoute = null;

    resetScroll = (param) => {
        const newRoute = param.routes[param.routes.length-1];

        if (!this.lastRoute || (this.lastRoute && newRoute != this.lastRoute)) {
            $(window).scrollTop(0);
            this.lastRoute = newRoute;
        }
    }

    onEnterApp = (nextState) => {
        let hasQueryParams = nextState && nextState.location && nextState.location.query;

        // Record the merchant reference in localstorage and cookie. We'll need this when the user subscribes.
        if (hasQueryParams && nextState.location.query.mref) {
            setMref(nextState.location.query.mref);
        }

        if (hasQueryParams && nextState.location.query.email) {
            store.set('default-email', nextState.location.query.email);
        }

        const mvars = store.get('m_var') || {};
        let isNewCampaign = false;

        // creating an object we can throw any future marketing variables into
        // using this for analytics and cohorts
        if (hasQueryParams &&
            nextState.location.query.utm_campaign &&
            (!mvars || !mvars.campaign || nextState.location.query.utm_campaign != mvars.campaign)) {
            isNewCampaign = true;
            let marketing_variables = {
                source: nextState.location.query.utm_source,
                campaign: nextState.location.query.utm_campaign || null,
                medium: nextState.location.query.utm_medium     || null,
                content: nextState.location.query.utm_content   || null,
                sref: nextState.location.query.sref             || null
            };
            store.set('m_var', marketing_variables);
        }

        Analytics.initialize();
        Analytics.launchApp(isNewCampaign);
        ABTest.initializeTests();

        // Cleanup local storage now, then every 15 minutes.
        cleanupLocalStorage();
        setInterval(() => {
            cleanupLocalStorage();
        }, 15 * 60 * 1000);

        return true;
    }

    redirectOauth2 = (nextState) => {
        let hasQueryParams = nextState && nextState.location && nextState.location.query;
        let hasWindowOpener = window.opener && window.opener.authorizeProvider;

        if (hasQueryParams && hasWindowOpener) {
            const code = nextState.location.query.code || null;
            const error = nextState.location.query.error || null;

            window.opener.authorizeProvider(code, error);
        }
    }

    render() {
        const { resetScroll } = this;

        let history = browserHistory;

        // Did props tell  us to use a different history mechanism?
        switch(this.props.history_method) {
            case 'hash':
                history = hashHistory;
                break;
        }

        if (!window.name) {
            window.name = 'lp';
        }

        SessionRecorder.config(history);

        return (
            <Router history={history} onUpdate={this.onPageUpdate}>
                <Route path="/" component={Root} onEnter={this.onEnterApp}>
                    <IndexRoute component={HomePage} onEnter={resetScroll} hideToolbar />

                    <Route path="nutrition" onEnter={resetScroll} component={Dashboard}>
                        <IndexRoute
                            onEnter={resetScroll}
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./pages/Nutrition.react').default);
                                });
                            }}
                        />
                    </Route>

                    <Redirect from="planner/groceries(/:date)" to="/groceries" />
                    <Route
                        path="groceries"
                        onEnter={resetScroll}
                        loggedOutHideToolbar={true}
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Groceries.react').default);
                            });
                        }}
                        toolTitle="Grocery List"
                    />

                    <Route path="log(/:date)" onEnter={resetScroll} component={Dashboard}>
                        <IndexRoute onEnter={resetScroll} component={require('./pages/DailyLog.react').default} />
                    </Route>

                    <Route path="messages(/:jid)" onEnter={resetScroll} component={Dashboard}>
                        <IndexRoute
                            onEnter={resetScroll}
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./pages/Messages.react').default);
                                });
                            }}
                        />
                    </Route>

                    <Route path="meals" onEnter={resetScroll} component={Dashboard}>
                        <Route
                            path=":date/:mealType"
                            onEnter={resetScroll}
                            component={require('./pages/MealDetails.react').default}
                        />
                    </Route>

                    {/* - CONTENT & LANDING PAGES - */}
                    <Route
                        path="about"
                        onEnter={resetScroll}
                        toolTitle="About EatLove"
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/AboutUs.react').default);
                            });
                        }}
                    />

                    <Route
                        path="terms"
                        onEnter={resetScroll}
                        toolTitle="Terms of Use"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Terms.react').default);
                            });
                        }}
                    />

                    <Route
                        path="privacy"
                        onEnter={resetScroll}
                        toolTitle="Privacy Policy"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/PrivacyPolicy.react').default);
                            });
                        }}
                    />

                    <Route
                        path="references"
                        onEnter={resetScroll}
                        toolTitle="References"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/References.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/HomePage.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/medical"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/Medical.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/medical/pricing"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/MedicalPricing.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/dietitian"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/Dietitian.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/fitness"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/Fitness.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/fitness/pricing"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/FitnessPricing.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/food-industry"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/Restaurants.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/healthplan-payers-employers"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/HealthPlan.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/medical-enterprise"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/MedicalGroups.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/retailers"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/Retailers.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/api"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/DeveloperApi.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/hospitality"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/Hospitality.react').default);
                            });
                        }}
                    />

                    <Route
                        path="pro/coach"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/BuiltIns/WellnessCoach.react').default);
                            });
                        }}
                    />

                    <Route
                        path="youfit"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/YouFit/Landing.react').default);
                            });
                        }}
                    />

                    <Route
                        path="fit"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Fit/Landing.react').default);
                            });
                        }}
                    />

                    {/* - MISCELLANEOUS FUNCTION PAGES - */}
                    <Route
                        path="confirm"
                        onEnter={resetScroll}
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Confirm.react').default);
                            });
                        }}
                    />

                    <Route
                        path="recover-account"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/RecoverAccount.react').default);
                            });
                        }}
                    />

                    <Route
                        path="unsubscribe"
                        onEnter={resetScroll}
                        toolTitle="Account Settings"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Unsubscribe.react').default);
                            });
                        }}
                    />

                    <Route
                        path="try-it-out"
                        onEnter={resetScroll}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/TryItOut.react').default);
                            });
                        }}
                    />

                    <Route
                        path="track"
                        onEnter={resetScroll}
                        toolTitle="Redirecting..."
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Track.react').default);
                            });
                        }}
                    />

                    <Route
                        path="learn"
                        onEnter={resetScroll}
                        toolTitle=" "
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Learn.react').default);
                            });
                        }}
                    />

                    <Route
                        path="search"
                        onEnter={resetScroll}
                        toolTitle="Search for Meals"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Search.react').default);
                            });
                        }}
                    />

                    <Route
                        path="nutrition-academy"
                        onEnter={resetScroll}
                        toolTitle=" "
                        flatPageHeader={true}
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pro/pages/NutritionAcademy.react').default);
                            });
                        }}
                    />

                    <Route
                        path="food-banks"
                        onEnter={resetScroll}
                        toolTitle="Food Bank Finder"
                        flatPageHeader={true}
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/FoodBanks.react').default);
                            });
                        }}
                    />

                    <Route
                        path="survey/:uuid"
                        onEnter={resetScroll}
                        toolTitle="EatLove"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Survey.react').default);
                            });
                        }}
                    />

                    <Redirect from="dietitians" to="/" />
                    <Redirect from="dietitians/signup" to="/" />
                    <Redirect from="dietitians/confirmed" to="/" />

                    {/* - REGISTRATION / ONBOARDING - */}
                    <Route
                        path="new-account"
                        onEnter={resetScroll}
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Register.react').default);
                            });
                        }}
                    />

                    <Route
                        path="accept-invite"
                        onEnter={resetScroll}
                        hideBackButton={true}
                        hideNavigation={true}
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/AcceptInvite.react').default);
                            });
                        }}
                    />

                    <Route
                        path="accept-patient-invite"
                        onEnter={resetScroll}
                        toolTitle="Welcome to EatLove"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/AcceptInvite.react').default);
                            });
                        }}
                    />

                    {/* - CANONICAL RECIPE AND MEAL PLAN URL PAGES - */}
                    <Route
                        path="menus/:uuid/customize"
                        onEnter={resetScroll}
                        component={MenuCustomizer}
                        toolTitle="Meal Plan"
                        hideBackButton={true}
                        hideHeaderButtons={true}
                    />
                    <Redirect from="menus" to="search" />

                    <Route
                        path="menus/:uuid"
                        onEnter={resetScroll}
                        component={MenuViewer}
                        toolTitle="Meal Plan"
                        hideBackButton={true}
                        hideHeaderButtons={true}
                    />
                    <Redirect from="menus" to="search" />

                    <Route path="recipes/:uuid" onEnter={resetScroll} component={RecipeDetails} />
                    <Redirect from="recipes" to="search" />

                    <Route path="foods/:uuid" onEnter={resetScroll} component={FoodDetails} />
                    <Redirect from="foods" to="search" />

                    <Route
                        path="collections/:uuid"
                        onEnter={resetScroll}
                        component={CollectionDetails}
                        toolTitle="Recipe Collection"
                        hideBackButton={false}
                        hideHeaderButtons={true}
                    />
                    <Redirect from="menus" to="search" />

                    {/* - MY ACCOUNT PAGES - */}
                    <Route
                        path="my-account"
                        onEnter={resetScroll}
                        toolTitle="Account Settings"
                        component={require('./pages/MyAccount.react').default}
                    >
                        <Route
                            path="settings"
                            onEnter={resetScroll}
                            toolTitle="Account Settings"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./pages/MyAccount/Settings.react').default);
                                });
                            }}
                        />

                        <Route
                            path="household"
                            onEnter={resetScroll}
                            toolTitle="Account Settings"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./pages/MyAccount/Household.react').default);
                                });
                            }}
                        />

                        <Route
                            path="notifications"
                            onEnter={resetScroll}
                            toolTitle="Account Settings"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./pages/MyAccount/Notifications.react').default);
                                });
                            }}
                        />

                        <Redirect from="subscription" to="membership" />
                        <Redirect from="membership" to="/membership" />
                    </Route>

                    {/* - APPS AND DEVICES PAGES - */}
                    <Route
                        path="apps-and-devices"
                        onEnter={resetScroll}
                        toolTitle="Apps and Devices"
                        flatPageHeader={true}
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/AppsAndDevices.react').default);
                            });
                        }}
                    />

                    <Route
                        path="oauth/authorize"
                        onEnter={resetScroll}
                        hideBackButton={true}
                        hideNavigation={true}
                        hideToolbar
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/OAuth/Authorize.react').default);
                            });
                        }}
                    />

                    <Route path="fitbit/auth" onEnter={this.redirectOauth2} hideBackButton={true} hideToolbar />

                    <Route
                        path="membership"
                        onEnter={resetScroll}
                        toolTitle="Membership"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Membership.react').default);
                            });
                        }}
                    />

                    {/* - PREFERENCES PAGES - */}
                    <Route path="preferences(/:section)" onEnter={resetScroll} component={Dashboard}>
                        <IndexRoute
                            onEnter={resetScroll}
                            toolTitle="Meal Preferences"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./pages/Preferences.react').default);
                                });
                            }}
                        />
                    </Route>

                    {/* - BOARDS/LIBRARY PAGES - */}
                    <Route
                        path="favorites"
                        onEnter={resetScroll}
                        toolTitle="Favorites"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Favorites.react').default);
                            });
                        }}
                    />

                    <Redirect from="boards" to="favorites" />
                    <Redirect from="boards/:uuid" to="favorites" />
                    <Redirect from="embed" to="/accept-invite" />

                    {/* - CONTENT PUBLISHING TOOLS - */}
                    <Route
                        path="publisher"
                        onEnter={resetScroll}
                        toolTitle="Recipe Publisher"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./pages/Publisher/Dashboard.react').default);
                            });
                        }}
                    />

                    <Route
                        path="publisher/recipe(/:uuid)"
                        onEnter={resetScroll}
                        toolTitle="Recipe Publisher"
                        getComponent={(l, cb) => {
                            if (global.setIsChunkLoading) {
                                global.setIsChunkLoading(true);
                            }
                            return require.ensure([], (require) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(false);
                                }
                                cb(null, require('./components/Publisher/RecipePublisher.react').default);
                            });
                        }}
                    />

                    {/* - ADMIN TOOLS - */}
                    <Route
                        path="admin"
                        component={require('./pages/Admin.react').default}
                        toolTitle="EatLove Administration"
                    >
                        <IndexRoute
                            component={require('./components/Admin/Dashboard.react').default}
                            onEnter={resetScroll}
                            toolTitle="EatLove Admin"
                        />
                        <Route
                            path="foods(/:uuid)"
                            toolTitle="Foods Database"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/Foods.react').default);
                                });
                            }}
                        />
                        <Route
                            path="combos(/:uuid)"
                            toolTitle="Combo Manager"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/Combos.react').default);
                                });
                            }}
                        />
                        <Route
                            path="newsletters(/:uuid)"
                            toolTitle="Newsletter Manager"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/Newsletters.react').default);
                                });
                            }}
                        />
                        <Route
                            path="brands(/:uuid)"
                            toolTitle="Brands Manager"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/Brands.react').default);
                                });
                            }}
                        />
                        <Route
                            path="accounts"
                            toolTitle="Account Tools"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/Accounts.react').default);
                                });
                            }}
                        />
                        <Route
                            path="pages(/:uuid)"
                            toolTitle="Page Manager"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/Pages.react').default);
                                });
                            }}
                        />
                        <Route
                            path="categories(/:uuid)"
                            toolTitle="Category Manager"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/Categories.react').default);
                                });
                            }}
                        />
                        <Route
                            path="style-guide"
                            onEnter={resetScroll}
                            toolTitle="Style Guide Test Page"
                            getComponent={(l, cb) => {
                                if (global.setIsChunkLoading) {
                                    global.setIsChunkLoading(true);
                                }
                                return require.ensure([], (require) => {
                                    if (global.setIsChunkLoading) {
                                        global.setIsChunkLoading(false);
                                    }
                                    cb(null, require('./components/Admin/StyleGuide.react').default);
                                });
                            }}
                        />
                    </Route>

                    <Route
                        path="i/:shortcode"
                        component={require('./pages/ShortLinkRedirect.react').default}
                        toolTitle="EatLove"
                    />

                    <Route
                        path="**"
                        hideToolbar
                        onEnter={resetScroll}
                        component={require('./pages/DynamicPage.react').default}
                    />
                </Route>
            </Router>
        );
    }
}

window.App = App;
window.React = React;
window.ReactDOM = ReactDOM;
window.moment = moment;
window.setMref = setMref;
window.getMref = getMref;

export default Sentry.withProfiler(App);
