'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ImgResized from '../../Widgets/ImgResized.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';

import allMealKitBrands from '../../../tables/meal-kit-brands';

import './FoodCard.scss';
import './RecipeCard.scss';

export default class FoodCard extends Component {
    static propTypes = {
        food: PropTypes.object,
        isLogging: PropTypes.bool,
        onClickFoodCard: PropTypes.func
    };

    static defaultProps = {
    };

    static contextTypes = {
        isPublisher: PropTypes.bool,
        showResultsFavoriteBtn: PropTypes.bool,
        onSelectFood: PropTypes.func,
        showFoodDetails: PropTypes.func,
        alwaysSelectFood: PropTypes.bool,
    };

    onClick = (ev) => {
        const { onSelectFood, showFoodDetails, alwaysSelectFood } = this.context;
        const { food, isLogging, onClickFoodCard, organicRanking, searchRanking, searchTerm, sortBy, sortOrder, source } = this.props;

        ev.preventDefault();

        if (onClickFoodCard) {
            onClickFoodCard();
            return;
        }

        if (onSelectFood && isLogging) {
            onSelectFood && onSelectFood(food, undefined, undefined, undefined, undefined, undefined, {organicRanking, searchRanking, term: searchTerm, sortBy, sortOrder, source});
        } else if (alwaysSelectFood && onSelectFood) {
            onSelectFood(food, undefined, undefined, undefined, undefined, undefined, {organicRanking, searchRanking, term: searchTerm, sortBy, sortOrder, source});
        } else if (showFoodDetails) {
            showFoodDetails(food, null, {organicRanking, searchRanking, term: searchTerm, sortBy, sortOrder, source});
        }
    }

    link = (element) => {
        const { isPublisher } = this.context;
        const { food } = this.props;

        return <Link to={`/foods/${food.uuid}`} onClick={this.onClick}>{element}</Link>
    }

    render() {
        const { isPublisher, showResultsFavoriteBtn } = this.context;
        const { food, shortMode } = this.props;

        const aspect = !shortMode
                     ? { w: 400, h: 500 }
                     : { w: 600, h: 600 };

        const imageProps = {};

        if (food.image_thumb) {
            imageProps.style = {
                backgroundImage: 'url(data:image/jpeg;base64,' + food.image_thumb + ')',
            };

            imageProps['data-has-thumb'] = true;
        }
        return (
            <div className="recipe-card food-card">
                {this.link(food.image
                    ? <ImgResized className="recipe-card-image" src={food.image} onClick={this.onClick} width={aspect.w} height={aspect.h} {...imageProps} />
                    : <div className="recipe-card-image">
                        {food.brand_name
                            ? <h2>{food.brand_name}</h2>
                            : <i className="icon-logo2"><span className="path1" /><span className="path2" /></i>
                        }
                      </div>
                )}
                {this.link(<div className="recipe-card-text">
                    {food.brand_uuid && allMealKitBrands[food.brand_uuid] ?
                        <img height="21" className="provider-logo" src={allMealKitBrands[food.brand_uuid].logo} />
                    : null}
                    <h3>{food.image && food.brand_name ? <>{food.brand_name}&nbsp;</> : null}
                    {food.pretty_name || food.name}</h3>
                </div>)}

                {isPublisher ?
                    <PublisherSubmenu resource={food} />
                : null}
                {showResultsFavoriteBtn ?
                    <FavoriteButton food={food} />
                : null}
            </div>
        );
    }
}
