'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class MyLibrary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            merchant: null,
        };
    }

    clearCalories = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.calories;

        onChangeParams(params, true);
    }

    render() {
        const { params = {} } = this.props
        const { merchant } = this.state;

        if (!(params && params.filters && params.filters.calories)) {
            return <span />;
        }

        const kcal = params.filters.calories;
        let label;

        if (kcal.gte && kcal.lte) {
            label = <em>Between {Math.round(kcal.gte)} - {Math.round(kcal.lte)} calories</em>;
        }

        if (kcal.gte && !kcal.lte) {
            label = <em>More than {Math.round(parseInt(kcal.gte) - 1)} calories</em>;
        }

        if (!kcal.gte && kcal.lte) {
            label = <em>Less than than {Math.round(kcal.lte)} calories</em>;
        }

        return (
            <div className="param-item" onClick={this.clearCalories}>
                {label} <i className="icon-close-x" />
            </div>
        );
    }
}
