'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import uuid from "uuid";

import Browser from '../../Browser.react';
import SearchKeywords from '../../Filters/SearchKeywords.react';
import Analytics from '../../../../utils/Analytics';
import AuthStore from '../../../../stores/AuthStore';
import FrequentlyUsed from './FullBrowser/FrequentlyUsed.react';
import ResultsMini from '../../ResultsMini.react';
import FoodEditorModal from '../../../Foods/Modals/FoodEditorModal';
import BarcodeScanner from '../../../Admin/Foods/Tools/BarcodeScanner.react';

import { getConfig } from '../../../../utils/Env';

import './FullBrowser.scss';
import './Restaurants.scss';

const RESULTS_SIZE = 50;

export default class FullBrowser extends Component {

    static contextTypes = {
        confirm: PropTypes.func,
        allowedTypes: PropTypes.array,
        addSwapContext: PropTypes.object,
        isMobile: PropTypes.bool,

        onSelectFood: PropTypes.func,
        onSelectFrequentlyUsed: PropTypes.func,
    };

    static propTypes = {
        profile: PropTypes.object,
        modalTitle: PropTypes.string,

        inhibitSearchOnMount: PropTypes.bool,
    };

    static defaultProps = {
        inhibitSearchOnMount: true,
        modalTitle: "Add Ingredient",
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            isAdvancedFiltersOpen: false,
            params: this.getDefaultParamsFromProps(props),
            dirty: props.inhibitSearchOnMount ? false : true,
            showAll: false,
            isFocused: false,
            barcodeRetryCount: 0,
            sessionSearchId: uuid.v4(),
            lastScannedBarcode: null
        };
    }


    handleBarcodeRetryCount = () => {
        const currentBarcode = this.state.missingBarcode;

        this.setState(prevState => {
            if (currentBarcode === prevState.lastScannedBarcode) {
                return {
                    barcodeRetryCount: prevState.barcodeRetryCount + 1,
                    lastScannedBarcode: currentBarcode,
                };
            } else {
                return {
                    barcodeRetryCount: 1,
                    lastScannedBarcode: currentBarcode,
                };
            }
        }, () => {
            if (this.state.barcodeRetryCount < this.refs.scanner.rageScanningThreshold) {
                this.refs.scanner.retryBarcodeScan();
            }
        });
    }

    resetBarcodeRetryCount = () => {
        this.setState({ barcodeRetryCount: 0 });
    }

    displayError = () => {
        return this.state.barcodeRetryCount === this.refs.scanner.rageScanningThreshold;
    }

    getDefaultParamsFromProps({profile, params, useComputedParams, defaultTerms = '', defaultTypes = [], allowedTypes = [], defaultParams}) {

        if (defaultParams) {
            return defaultParams;
        }

        if (useComputedParams) {
            return params;
        }

        const types = (defaultTypes && defaultTypes.length > 0 ? defaultTypes : null) ||
                      (allowedTypes && allowedTypes.length > 0 ? allowedTypes : null);

        return {
            language: profile.language || 'en',
            types: types.slice(),
            filters: {},
            include_library: true,
            size: RESULTS_SIZE,
            terms: defaultTerms,
            include_merchants: profile?.features?.source_libraries || null
        };
    }

    onShowAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: true});
    }

    onHideAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: false});
    }

    onExecuteSearch = (params) => {
        const { sessionSearchId } = this.state;

        Analytics.swapSearch(params.terms, params.filters, params.sort_by, sessionSearchId);
    }

    updateParent = () => {
        const { onChangeParams } = this.props;
        const { params } = this.state;

        onChangeParams && onChangeParams(params);
    }

    onChangeParams = (newParams, force = false) => {
        this.setState((prevState) => {
            const hadTerms = !!prevState.params.terms;
            const hasTerms = !!newParams.terms;

            const newState = {
                params: newParams,
                dirty: true,
            };

            if (hadTerms && !hasTerms) {
                newState.sessionSearchId = uuid.v4();
            }

            return newState;
        }, this.updateParent);
    }

    closeFoodEditorModal = () => {
        this.setState({isFoodsModalOpen: false, missingBarcode: '', barcodeRetryCount: 0, lastScannedBarcode: null});
    }

    onSaveFood = (food) => {
        const { onSelectFood } = this.context;

        Analytics.savedCustomFood({
            'Context': 'Add/Swap',
            'Food UUID': food.uuid
        });

        onSelectFood(food);
    }

    renderFoodEditorModal = () => {
        const { isFoodsModalOpen, missingBarcode = '' } = this.state;
        const { profile, modalTitle } = this.props;

        if (!isFoodsModalOpen) {
            return;
        }

        const introMessage = missingBarcode
            ? <span>Sorry, that barcode was not found in our database. We'd love to add it though! Help us out by taking a few pictures, and we will add the barcode to our database within 72 business hours.</span>
            : <span>Sorry we didn't have the food you're looking for. We'd love to add it though! Help us out by taking a few pictures, and we will add it to our database within 72 business hours.</span>;

        return (
            <FoodEditorModal
                closeModal={this.closeFoodEditorModal}
                handleBarcodeRetryCount={this.handleBarcodeRetryCount}
                resetBarcodeRetryCount={this.resetBarcodeRetryCount}
                displayError={this.displayError()}
                contentLabel="Edit Recipe Details"
                modalTitle={modalTitle}

                profile={profile}
                introMessage={introMessage}
                defaultBarcode={missingBarcode}
                onSaveFood={this.onSaveFood}
                retryBarcodeScan={this.refs.scanner.retryBarcodeScan}/>
        );
    }

    onBarcodeNotFound = (barcode, isManualEntry) => {
        const { confirm } = this.context;

        this.setState({isFoodsModalOpen: true, missingBarcode: barcode});
        return;
    }

    onBarcodeFound = (food) => {
        const { onSelectFood } = this.context;

        onSelectFood(food, undefined, undefined, undefined, undefined, undefined, {source: 'Barcode Scan'});

        this.setState({isFoodsModalOpen: false});

        if (typeof this.refs.scanner?.closeModal === 'function') {
            this.refs.scanner.closeModal();
        }
    }

    render() {
        const { params, isAdvancedFiltersOpen, dirty, isFocused } = this.state;
        const { profile, allowedTypes, inhibitSearchOnMount, onChangeMode } = this.props;
        const { addSwapContext, onSelectFrequentlyUsed, isMobile } = this.context;

        return (
            <div className="add-swap-full-browser" data-dirty={dirty}>
                <header>
                    <SearchKeywords params={params}
                        onFocus={() => this.setState({isFocused: true})}
                        onBlur={() => this.setState({isFocused: false})}
                        onChangeParams={this.onChangeParams}
                        className="full-browser-keyword-search"
                        placeholder={(addSwapContext && addSwapContext.fullBrowserSearchPlaceholder) || "What would you like to eat?"} />

                    {allowedTypes.includes('food') ?
                        <BarcodeScanner className="scan-barcode-btn" ref="scanner"
                        onBarcodeNotFound={this.onBarcodeNotFound}
                        onBarcodeFound={this.onBarcodeFound}
                        barcodeContext={"Search Foods Modal"} />
                    : null}

                    <button className="advanced-filters-btn" onClick={this.onShowAdvancedFilters}>
                        <i className="feather feather-filter" />
                    </button>
                </header>

                <Browser contextName="Meals" params={params} profile={profile}
                    onChangeParams={this.onChangeParams}
                    allowedTypes={allowedTypes}
                    inhibitSearchOnMount={inhibitSearchOnMount}
                    resultsComponent={addSwapContext.auto_log ? ResultsMini : null}
                    showTypePicker={true}
                    onShowAdvancedFilters={this.onShowAdvancedFilters}
                    onHideAdvancedFilters={this.onHideAdvancedFilters}
                    onExecuteSearch={this.onExecuteSearch}
                    isAdvancedFiltersOpen={isAdvancedFiltersOpen}
                    />

                {!dirty && inhibitSearchOnMount && onSelectFrequentlyUsed ?
                    <FrequentlyUsed />
                : null}

                {this.renderFoodEditorModal()}

                {!isMobile || !isFocused ? <footer className="search-foods-and-recipes-footer">
                    {allowedTypes.includes('food') ?
                        (
                            <div>
                            <span className="pink-square">
                                <BarcodeScanner className="scan-barcode-btn" ref="scanner"
                                    onBarcodeNotFound={this.onBarcodeNotFound}
                                    onBarcodeFound={this.onBarcodeFound}
                                    barcodeContext={"Full Browser"} />
                            </span>
                            <span className="pink-square-text">Scan a food item</span>
                            </div>
                        )
                    : null}

                    <div onClick={() => onChangeMode('create-custom')}>
                        <span className="pink-square">
                            <i className="icon-paper"/>
                        </span>
                        <span className="pink-square-text">Create custom food</span>
                        </div>

                        <div onClick={() => onChangeMode('use-own-recipe')}>
                        <span className="pink-square">
                            <i className="icon-add-circle-outline"/>
                        </span>
                        <span className="pink-square-text">Create recipe</span>
                    </div>
                </footer>
            : null }
            </div>
        );
    }
}
