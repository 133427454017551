'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import ImgResized from '../../Widgets/ImgResized.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';

import { fetchDocumentsById } from '../../../utils/Content';

import allMealKitBrands from '../../../tables/meal-kit-brands';

import './ComboCard.scss';
import './RecipeCard.scss';

export default class ComboCard extends Component {
    static propTypes = {
        combo: PropTypes.object,
    };

    static contextTypes = {
        isPublisher: PropTypes.bool,
        showResultsFavoriteBtn: PropTypes.bool,
        onSelectCombo: PropTypes.func,
        showComboDetails: PropTypes.func,
        addSwapContext: PropTypes.object,
    };

    onClick = async () => {
        const { onSelectCombo, showComboDetails, addSwapContext } = this.context;
        const { combo, isLogging, organicRanking, searchRanking, resultType, searchTerm, sortBy, sortOrder } = this.props;

        if (onSelectCombo && isLogging) {
            const documents = await fetchDocumentsById([combo.main_dish, combo.side_dish]);

            let mainDish, sideDish;

            documents.forEach(doc => {
                if (combo.main_dish === doc.uuid) {
                    mainDish = doc;
                }

                if (combo.side_dish === doc.uuid) {
                    sideDish = doc;
                }
            });

            onSelectCombo(combo, mainDish, sideDish, addSwapContext.participants, {organicRanking, searchRanking, resultType, term: searchTerm, sortBy, sortOrder});
        } else if (showComboDetails) {
            showComboDetails(combo);
        }
    }

    link = (element) => {
        const { combo } = this.props;
        const { isPublisher } = this.context;

        if (isPublisher && combo.uuid) {
            return <Link to={`/admin/combos/${combo.uuid}`}>{element}</Link>
        }

        return <span onClick={this.onClick}>{element}</span>
    }

    render() {
        const { isPublisher, showResultsFavoriteBtn } = this.context;
        const { combo } = this.props;

        return (
            <div className="recipe-card combo-card">
                {this.link(combo.main_image
                    ? <ImgResized className="recipe-card-image" src={combo.main_image} width={300} height={300} />
                    : <div className="recipe-card-image">
                        {combo.main_brand_name
                            ? <h2>{combo.main_brand_name}</h2>
                            : <i className="icon-logo2"><span className="path1" /><span className="path2" /></i>
                        }
                      </div>
                )}
                {this.link(<ImgResized className="combo-card-side-image" src={combo.side_image} width={240} height={100} />)}
                {this.link(<div className="recipe-card-text">
                    {combo.main_brand_uuid && allMealKitBrands[combo.main_brand_uuid] ?
                        <img height="21" className="provider-logo" src={allMealKitBrands[combo.main_brand_uuid].logo} />
                    : null}

                    <h3>{combo.main_title} with {combo.side_title}</h3>
                    {isPublisher ?
                        <div className={combo.status === 'live' ? 'publish-status live' : 'publish-status'}>
                            {combo.status === 'live' ? 'live' : 'draft'}
                        </div>
                    : null}
                </div>)}
                {isPublisher
                    ? <PublisherSubmenu resource={combo} />
                : null}
                {showResultsFavoriteBtn ?
                    <FavoriteButton combo={combo} />
                : null}
            </div>
        );
    }
}
