'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import Popup from '../../Widgets/Popup.react';
import EditIngredient from './EditIngredient.react';
import SearchFoodsModal from '../../Foods/SearchFoodsModal.react';
import LogPortionsModal from '../../Planner/Modals/LogPortionsModal.react';

import Analytics from '../../../utils/Analytics';

import './EditIngredientGroup.scss';

export default class EditIngredientGroup extends Component {

    static propTypes = {
        recipe: PropTypes.object,
        details: PropTypes.object,

        moveIngredient: PropTypes.func,
        onChangeDetails: PropTypes.func,
    };

    static defaultProps = {
    };

    static contextTypes = {
        profile: PropTypes.object,
        confirm: PropTypes.func,
    };

    static childContextTypes = {
        addSwapContext: PropTypes.object,
    };

    constructor(props) {
        super(props);

        this.state = {
            addIngredientToGroup: null,
            swapIngredientFood: null,
            swapIngredientFoodIndex: null,
            titleFocused: false,
            title: props.group.title || "",
        };

        this.groupDropdown = createRef();
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.group.title && this.props.group.title) {
        this.setState({title: this.props.group.title});
      }
    }

    getChildContext = () => {
        return {
            addSwapContext: {auto_log: true},
        };
    }

    onChangeIngredient = (ingredient, k) => {
        const { details, groupIndex, onChangeDetails } = this.props;

        details.ingredients[groupIndex].items[k] = ingredient;

        onChangeDetails(details, true);
    }

    onRemoveIngredient = (k) => {
        const { details, groupIndex, onChangeDetails } = this.props;

        details.ingredients[groupIndex].items.splice(k, 1);

        onChangeDetails(details, true);
    }

    onSwapIngredient = (swapIngredientFood, swapIngredientFoodIndex) => {

        this.setState({swapIngredientFood, swapIngredientFoodIndex});
    }

    setFoodMapping = (ingredient, food, unit) => {
        delete ingredient.recipe;

        ingredient.ingredient = food.pretty_name || food.name;
        ingredient.percent_yield = food.percent_yield || 100;
        ingredient.refuse = food.refuse || 0;

        ingredient.food = {
            uuid: food.uuid,
            name: food.name,
            automatic: false,
            verified: true,
        };

        if (unit) {
            ingredient.food.unit_amount = unit.amount;
            ingredient.food.unit_grams = unit.grams;
            ingredient.food.unit_milliliters = unit.milliliters;
            ingredient.food.unit_description = unit.description;
        }

        return ingredient;
    }

    closeAllModals = () => {
        this.setState({addIngredientToGroup: null, logPortionsFor: null, logPortionsParams: null, swapIngredientFood: null, swapIngredientFoodIndex: null});
    }

    closeAddSwapModal = () => {
        this.setState({addIngredientToGroup: null, swapIngredientFood: null, swapIngredientFoodIndex: null});
    }

    closeLogPortionsModal = () => {
        this.setState({logPortionsFor: null, logPortionsParams: null});
    }

    onSelectFood = (food) => {
        this.setState({
            logPortionsFor: [food],
            logPortionsParams: {},
        });
    }

    onSelectPortions = (contents, portions) => {
        const { details, groupIndex, onChangeDetails } = this.props;
        const { swapIngredientFoodIndex, swapIngredientFood } = this.state;

        const content = contents[0];
        const { logged_unit, logged_amount, logged_grams, logged_milliliters, unit } = portions[content.uuid];

        let ingredient = swapIngredientFoodIndex != null && details.ingredients[groupIndex].items[swapIngredientFoodIndex]
                       ? details.ingredients[groupIndex].items[swapIngredientFoodIndex]
                       : {};

        ingredient.measurement = {
            amount: logged_amount,
            unit_of_measure: logged_unit,
        };

        ingredient.grams = logged_grams;
        ingredient.milliliters = logged_milliliters;
        ingredient.automatic = false;
        ingredient.verified = true;

        // Should always have something...
        if (content.type === 'food') {
            this.setFoodMapping(ingredient, content, unit);
        }

        // Ensure the ingredients structure is initialized
        details.ingredients = details.ingredients || [];
        details.ingredients[groupIndex] = details.ingredients[groupIndex] || {items: []};
        details.ingredients[groupIndex].items = details.ingredients[groupIndex].items || [];

        // Add or update the food
        if (swapIngredientFoodIndex != null && details.ingredients[groupIndex].items[swapIngredientFoodIndex]) {
            details.ingredients[groupIndex].items[swapIngredientFoodIndex] = ingredient;
        } else {
            details.ingredients[groupIndex].items.push(ingredient);
        }

        onChangeDetails(details, true);
        this.closeAllModals();


        Analytics.addIngredientToRecipe({
            'Food UUID': content.uuid,
            'Type': content.type
        });
    }


    onChangeGroupTitle = (ev) => {
        this.setState({title: ev.target.value});
    }

    updateGroupTitle = (ev) => {
        const { groupIndex, details, onChangeDetails } = this.props;
        const { title } = this.state;

        details.ingredients[groupIndex].title = title;

        onChangeDetails(details, true);
    }

    renderAddIngredientModal = () => {
        const { addIngredientToGroup, swapIngredientFoodIndex } = this.state;
        const { profile } = this.context;
        const { recipe } = this.props;

        if (addIngredientToGroup || swapIngredientFoodIndex != null) {
            const modalTitle = swapIngredientFoodIndex !== null ? 'Swap an Ingredient' : 'Add an Ingredient';

            return (
                <SearchFoodsModal closeModal={this.closeAddSwapModal}
                    defaultLanguage={recipe.language}
                    allowedTypes={['food']}
                    profile={profile}
                    modalTitle={modalTitle}
                    onSelectFood={this.onSelectFood} />
            );
        }
    }


    renderLogPortionsModal = () => {
        const { profile } = this.context;
        const { logPortionsFor, swapIngredientFood } = this.state;

        if (!logPortionsFor) {
            return null;
        }

        const defaultPortions = [];

        if (swapIngredientFood) {
            logPortionsFor.forEach((content) => {

                if ((content.serving_unit == "g" && swapIngredientFood.food.unit_grams) ||
                    (content.serving_unit == "ml" && swapIngredientFood.food.unit_milliliters)) {

                    const portion = {
                        "food_uuid": content.uuid,
                        "logged_portion": 1,
                        "logged_unit": swapIngredientFood.food.unit_description,
                        "logged_amount": swapIngredientFood.measurement.amount,
                        "logged_grams": swapIngredientFood.food.unit_grams ? 
                            swapIngredientFood.food.unit_grams * swapIngredientFood.measurement.amount : null,
                        "logged_milliliters": swapIngredientFood.food.unit_milliliters ?
                            swapIngredientFood.food.unit_milliliters * swapIngredientFood.measurement.amount : null
                    }

                    defaultPortions.push(portion);
                }
            })            
        }

        return <LogPortionsModal closeModal={this.closeLogPortionsModal}
            profile={profile}
            modalTitle="How much?"
            ctaText="Add Food"
            contents={logPortionsFor}
            onSelectPortions={this.onSelectPortions}
            defaultPortions={defaultPortions} />
    }

    onAddIngredientGroup = () => {
        const { groupIndex, addIngredientGroup } = this.props;

        this.groupDropdown.current.closeDropdown();
        addIngredientGroup(groupIndex);
    }

    onDeleteIngredientGroup = () => {
        const { groupIndex, deleteIngredientGroup } = this.props;
        const { confirm } = this.context;

        this.groupDropdown.current.closeDropdown();

        confirm(
            <div>
                <h2>Are you sure you want to delete this ingredient group?</h2>
                <p>This cannot be undone</p>
            </div>,
            accept => deleteIngredientGroup(groupIndex),
            reject => false,
            {acceptText: 'Delete Group', rejectText: 'Cancel'}
        );
    }

    onEditIngredientGroupName = () => {
        this.nameInput.focus();
        this.groupDropdown.current.closeDropdown();
    }

    render = () => {
        const { group, groupIndex, moveIngredient, deleteIngredientGroup } = this.props;
        const { titleFocused, title } = this.state;
        const { profile } = this.context;

        return (
            <div className="edit-recipe-group ingredient-group-item-list">
                <header>
                    <input className="group-title" onFocus={() => this.setState({titleFocused: true})} onBlur={() => this.setState({titleFocused: false}, this.updateGroupTitle)} ref={(input) => { this.nameInput = input; }}  type="text" value={title || ''} onChange={this.onChangeGroupTitle} placeholder={ titleFocused ?  "Enter ingredient group name" : `Meal Ingredients ${groupIndex ?  groupIndex + 1 : ""}`} />

                    <Popup positionClassName="el-popup-right-top-center" className="group-menu" ref={this.groupDropdown} button={<i className="icon-ellipsis2" />}>
                        <div>
                            <h4>Ingredient Group Options</h4>

                            <footer>
                                <button className="delete-group-btn" onClick={this.onAddIngredientGroup}>add group</button>
                                <button className="delete-group-btn" onClick={()=> {this.onEditIngredientGroupName()}}>edit name</button>
                                {deleteIngredientGroup ?
                                    <button className="delete-group-btn" onClick={this.onDeleteIngredientGroup}>delete group</button>
                                : null}
                            </footer>
                        </div>
                    </Popup>
                </header>

                <footer>
                    <button className="add-food-btn" onClick={() => this.setState({addIngredientToGroup: true})}>
                        <i className="icon-plus-thin" /> Add
                    </button>
                </footer>

                <div>
                    {group.items.map((ingredient, k) => (
                        <EditIngredient key={k} ingredient={ingredient} profile={profile}
                            moveIngredient={moveIngredient}
                            onChangeIngredient={(ingredient) => this.onChangeIngredient(ingredient, k)}
                            onRemoveIngredient={(ingredient) => this.onRemoveIngredient(k)}
                            onSwapIngredient={() => this.onSwapIngredient(ingredient, k)} />
                    ))}
                </div>

                {this.renderAddIngredientModal()}
                {this.renderLogPortionsModal()}
            </div>
        );
    }

}
