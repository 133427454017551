'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { pluralize, singularize } from 'inflected';
import moment from 'moment';

import AuthStore from '../../../../../stores/AuthStore';
import UserStore from '../../../../../stores/UserStore';
import BoardStore from '../../../../../stores/BoardStore';
import MealStore from '../../../../../stores/MealStore';

import { updateCachedDocuments } from '../../../../../utils/Content';
import { getAssetsForMeals, getPrimaryMeal } from '../../../../../utils/Meals';
import { getNutrientsForMeals } from '../../../../../utils/Nutrition';
import { isSingular, roundForHumans } from '../../../../../utils/Math';
import { getConfig } from '../../../../../utils/Env';
import { isSunbasketFood } from '../../../../../utils/Sunbasket';

import SmartFraction from '../../../../Widgets/SmartFraction.react';
import ImgResized from '../../../../Widgets/ImgResized.react';

import './FrequentlyUsed.scss';

export default class FrequentlyUsed extends Component {

    static contextTypes = {
        addSwapContext: PropTypes.object,
        user: PropTypes.object,
        isMobile: PropTypes.bool,

        onSelectRecipe: PropTypes.func,
        onSelectFood: PropTypes.func,
        onSelectFrequentlyUsed: PropTypes.func.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            assets: {},
            mode: 'foods',
            loading: true,
            showAll: false,
            popularFoods: [],
        };
    }

    componentDidMount = () => {
        this.loadFrequentlyUsed();
    }

    hydrateFrequentlyUsed = (meals, user, assets) => {
        let frequentlyUsed = [];
        const contents = {...assets.recipes, ...assets.foods};

        meals.forEach(frequentMeal => {
            frequentMeal.primary = getPrimaryMeal(frequentMeal.items, assets.recipes, assets.foods);

            frequentMeal.items.forEach(frequentItem => {
                const { content, titles, photos } = getPrimaryMeal([frequentItem], assets.recipes, assets.foods);

                if (!content) {
                    return;
                }

                if (!frequentItem.logged_amount && content.default_unit) {
                    const { description, amount, grams, milliliters } = content.default_unit || {};

                    Object.assign(frequentItem, {
                        logged_unit: description,
                        logged_amount: amount,
                        logged_grams: grams,
                        logged_milliliters: milliliters,
                    });
                } else if (!frequentItem.logged_amount && content.type === 'recipe' && content.milliliters_per_serving) {
                    Object.assign(frequentItem, {
                        logged_amount: 1,
                        logged_unit: 'serving',
                        logged_milliliters: content.milliliters_per_serving,
                    });
                }

                frequentItem.primary = {content, titles, photos};
                frequentItem.nutrients = getNutrientsForMeals([frequentItem], contents, user.portion);
                frequentItem.isBoarded = BoardStore.getBoardsByResourceId(content.uuid).length > 0
            });

            frequentMeal.nutrients = getNutrientsForMeals(frequentMeal.items, contents, user.portion);

            frequentlyUsed.push(frequentMeal);
        });

        return frequentlyUsed;
    }

    getPopularFoods = (user, mealType) => {
        let tags = ['Frequently Used'];
        let any_of_tag = [];

        if (mealType === 'Dinner' || mealType === 'Lunch') {
            any_of_tag.push('Main Dish', 'Lunch');
        } else {
            tags.push(mealType);
        }

        const params = {
            language: user.language,
            types: ['food'],
            filters: {
                'tags': tags,
                'any_of_tag': any_of_tag,
                '!ingredient_tags': user.preferences.avoidances,
            },
            include_merchants: user?.features?.source_libraries || null
        }

        return AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        });
    }

    unshiftRecentMeals = async (elements, mealType, type) => {
        let mealTypes = [mealType];
        
        if (mealType === "Lunch" || mealType === "Dinner") {
            mealTypes = ["Lunch", "Dinner"];
        }

        let types = ["food", "fresh"];

        if (type === "food") {
            types = ["food"];
        }

        if (type === "recipe") {
            types = ["fresh"];
        }

        const allMeals = MealStore.getMeals().filter(meal => 
            mealTypes.includes(meal.meal) && 
            types.includes(meal.meal_type) &&
            !meal.deleted
        );

        let recentlyAdded = allMeals.filter(meal => !meal.plan_uuid)
                                    .sort((a, b) => moment(b.created).valueOf() - moment(a.created).valueOf());

        // Filter for contemporary meals (from 5 days ago to 2 days from now)
        const fiveDaysAgo = moment().subtract(5, 'days').startOf('day');
        const twoDaysFromNow = moment().add(2, 'days').endOf('day');

        let contemporaryMeals = allMeals.filter(meal => {
            const mealDate = moment(meal.date);
            return mealDate.isBetween(fiveDaysAgo, twoDaysFromNow);
        });

        // Initialize uniqueMeals with the keys from the existing elements to avoid duplicates
        const uniqueMeals = new Set(
            elements.flatMap(used => 
                used.items.map(item => item.recipe_uuid || item.food_uuid)
            )
        );

        // Function to remove Sunbasket meals
        const removeSunbasketMeals = async (meals) => {
            const assets = await getAssetsForMeals(meals);
            return meals.filter(meal => {
                const food_uuid = meal.food_uuid;
                return !(food_uuid in assets.foods && isSunbasketFood(assets.foods[food_uuid]));
            });
        };

        // Remove Sunbasket meals from both recentlyAdded and contemporaryMeals
        recentlyAdded = await removeSunbasketMeals(recentlyAdded);
        contemporaryMeals = await removeSunbasketMeals(contemporaryMeals);

        // We want at least 10 recent items but we will take more if there are not many elements
        let leftToAdd = Math.max(20 - elements.length, 10);

        const addedDatesMeals = new Set();

        recentlyAdded.forEach((recent) => {
            const key = recent.recipe_uuid || recent.food_uuid;

            if (!key || leftToAdd <= 0 || uniqueMeals.has(key)) {
                return;
            }

            uniqueMeals.add(key);
            leftToAdd -= 1;

            const used = {
                meal: recent.meal,
                last_used: recent.date,
                items: [{
                    ...recent,
                }]
            };

            elements.unshift(used);
        });

        // Add contemporary meals to the elements, avoiding duplicates with both recentlyAdded and elements
        contemporaryMeals.forEach((contemporary) => {
            const key = contemporary.recipe_uuid || contemporary.food_uuid;

            if (!key || uniqueMeals.has(key)) {
                return;
            }

            uniqueMeals.add(key);

            const used = {
                meal: contemporary.meal,
                last_used: contemporary.date,
                items: [{
                    ...contemporary,
                }]
            };

            elements.unshift(used);
        });

        return elements;
    }

    loadFrequentlyUsed = async () => {
        const { user, addSwapContext } = this.context;
        let { mode } = this.state;

        const query = {meal: addSwapContext.mealType, include_all: 1};
        let results = await AuthStore.fetch({url: UserStore.getLinks().frequentlyUsed, query});

        const items = results.elements.reduce((all, freq) => {
            const itemsToAdd = freq.items.filter(item => !all.find(used =>
                (item.food_uuid && used.food_uuid && item.food_uuid == used.food_uuid) ||
                (item.recipe_uuid && used.recipe_uuid && item.recipe_uuid == used.recipe_uuid)
            ))
            return all.concat(itemsToAdd)
        }, []);

        const assets = await getAssetsForMeals(items);

        results.elements.forEach(element => {
            element.items = element.items.filter(item => {
                if (item.meal_type === "food") {
                    const food_uuid = item.food_uuid;

                    // If the food_uuid is not in assets.foods, or if it is but not a Sunbasket food, keep it
                    return !(food_uuid in assets.foods && isSunbasketFood(assets.foods[food_uuid]));
                }
                return true;
            });
        });

        // Remove frequent items that only contained Sunbasket foods 
        results.elements = results.elements.filter(element => element.items.length > 0);

        let meals = await this.unshiftRecentMeals(results.elements.filter(result => result.type === "meal"), addSwapContext.mealType, "meal");
        let recipes = await this.unshiftRecentMeals(results.elements.filter(result => result.type === "recipe"), addSwapContext.mealType, "recipe");
        let foods = await this.unshiftRecentMeals(results.elements.filter(result => result.type === "food"), addSwapContext.mealType, "food");

        const newItems = [].concat(
            ...meals.map(meal => meal.items || []),
            ...recipes.map(recipe => recipe.items || []),
            ...foods.map(food => food.items || [])
        );

        const newAssets = await getAssetsForMeals(newItems);

        const combinedAssets = {
            foods: { ...assets.foods, ...newAssets.foods },
            recipes: { ...assets.recipes, ...newAssets.recipes },
        };


        meals = this.hydrateFrequentlyUsed(meals, user, combinedAssets);
        recipes = this.hydrateFrequentlyUsed(recipes, user, combinedAssets);
        foods = this.hydrateFrequentlyUsed(foods, user, combinedAssets);

        if (meals.length <= 0) {
            mode = 'foods';
        }

        const foodUuids = foods.map(meal => meal.food_uuid).filter(v => v);
        let popularFoods = await this.getPopularFoods(user, addSwapContext.mealType);

        updateCachedDocuments(popularFoods.elements);

        // Strip any logged or recently used foods from our popular foods (so we don't show them twice)
        popularFoods.elements = popularFoods.elements.filter(pf => !foodUuids.includes(pf.uuid));

        this.setState({ meals, recipes, foods, assets: combinedAssets, mode, loading: false, popularFoods: popularFoods.elements});
    }

    renderPopularFood = (food, i) => {
        const { user: { preferences }, onSelectFood } = this.context;
        const { hide_nutrition = false } = preferences;

        let calories = (food.nutrients && food.nutrients.values && food.nutrients.values[208]) || 0;
        let serving_size = food.serving_unit === 'ml' ? food.milliliters_per_serving : food.grams_per_serving;
        let servingDescription = 'serving';

        if (food.default_unit && serving_size) {
            let { description, amount, grams, milliliters } = food.default_unit;

            calories = food.serving_unit === 'ml'
                     ? (calories / serving_size * milliliters)
                     : (calories / serving_size * grams);

            description = isSingular(amount) ? singularize(description) : pluralize(description)
            description = description || 'item';

            servingDescription = <span><SmartFraction value={amount} showZero={true} /> {description}</span>
        }

        return (
            <li key={i} data-food={food.brand_name && food.brand_name.length > 0} onClick={() => onSelectFood(food)}>
                {food.image
                    ? <ImgResized className="thumb" src={food.image} width={100} height={100} />
                    : <i className="icon-logo3 thumb" />
                }
                <span className="title">{food.brand_name ? <em>{food.brand_name}</em> : null}{food.pretty_name || food.name}</span>
                {!hide_nutrition ? <span className="cals">{roundForHumans(calories)} kcal per {servingDescription}</span> : null}
            </li>
        );
    }


    onSelectItem = (items, assets) => {
        const { onSelectFrequentlyUsed } = this.context;

        onSelectFrequentlyUsed(items, assets);
    }

    renderFrequentMeal = ({primary = {}, nutrients = {}, items = []}, i) => {
        const { assets } = this.state;
        const { user: { preferences } } = this.context;
        const { content, titles, photos } = primary;
        const { hide_nutrition = false } = preferences;

        let calories = nutrients[208] || 0;
        let servingDescription = 'serving';

        if (!content) {
            return;
        }

        if (items.length == 1) {
            let { logged_amount, logged_unit, logged_grams, logged_milliliters } = items[0];

            if (logged_amount) {
                logged_unit = isSingular(logged_amount) ? singularize(logged_unit) : pluralize(logged_unit)
                logged_unit = logged_unit || 'item';

                servingDescription = <span><SmartFraction value={logged_amount} showZero={true} /> {logged_unit}</span>
            }
        }

        return (
            <li key={i} data-food={content.brand_name && content.brand_name.length > 0} onClick={() => this.onSelectItem(items, assets)}>
                {photos[0]
                    ? <ImgResized className="thumb" src={photos[0].url} width={100} height={100} />
                    : <i className="icon-logo3 thumb" />
                }
                <span className="title">{content.brand_name ? <em>{content.brand_name}</em> : null}{titles.join(' + ')}</span>
                {!hide_nutrition ?
                    <span className="cals">{roundForHumans(calories)} kcal per {servingDescription}</span>
                : null}
            </li>
        );
    }

    renderTypePicker = () => {
        const { meals, recipes, foods, assets, mode, popularFoods } = this.state;

        const typePicks = [];

        if (foods?.length > 0 || (popularFoods && popularFoods.length > 0)) {
            typePicks.push(<li key="foods"><button data-active={mode === "foods"} onClick={() => this.setState({mode: 'foods'})}>foods</button></li>);
        }

        if (meals && meals.length > 0) {
            typePicks.push(<li key="meals"><button data-active={mode === "meals"} onClick={() => this.setState({mode: 'meals'})}>meals</button></li>);
        }

        if (recipes?.length > 0 ) {
            typePicks.push(<li key="recipes"><button data-active={mode === "recipes"} onClick={() => this.setState({mode: 'recipes'})}>recipes</button></li>);
        }

        if (typePicks.length > 1) {
            return <ul className="type-picker">{typePicks}</ul>
        }
    }


    getFrequentlyUsedArray = () => {
        const { meals, recipes, foods,  mode} = this.state

        let resultArray = [];

        if (mode === 'foods' && foods && foods.length) {
            resultArray = foods;

        } else if (mode === 'meals') {
            resultArray = meals;

        } else if (mode === 'recipes' && recipes && recipes.length) {
            resultArray = recipes;
        }

        const allMeals = MealStore.getMeals()

        // Use logged amount of latest instance of this item
        resultArray.forEach(result => {
            let key = result.food_uuid ? 'food_uuid' : null;
            key = result.recipe_uuid ? 'recipe_uuid' : key;

            if (!key) {
                return;
            }

            const matchingMeals = allMeals.filter(meal => meal[key] === result[key]);

            if (matchingMeals.length === 0) {
                return;
            }

            matchingMeals.sort((a, b) => new Date(b.date) - new Date(a.date));
            const latestMeal = matchingMeals[0];

            result.logged_amount = latestMeal.logged_amount;
            result.logged_unit = latestMeal.logged_unit;

            if (latestMeal.logged_grams !== undefined) {
                result.logged_grams = latestMeal.logged_grams;
            }

            if (latestMeal.logged_milliliters !== undefined) {
                result.logged_milliliters = latestMeal.logged_milliliters;
            }
        });

        return resultArray || [];
    }

    render = () => {
        const { mode, popularFoods, showAll, loading } = this.state;
        const { isMobile } = this.context;

        const numItemsToDisplay = isMobile ? 5 : 15;

        const results = this.getFrequentlyUsedArray();

        const primaryItemsToShow = showAll || results.length === 0 ? results : results.slice(0,numItemsToDisplay);
        const remainingItemsCount = Math.max(numItemsToDisplay - results.length, 0);

        const popularFoodsToShow = showAll || !popularFoods || popularFoods.length === 0 ? popularFoods : popularFoods.slice(0, remainingItemsCount);
        const totalItemCount = mode === 'foods' ? primaryItemsToShow.length +  popularFoodsToShow.length : primaryItemsToShow.length;

        if (loading) {
            return (
                <div  className="frequently-used" >
                    <h3>Frequently Used</h3>
                    <div className="frequently-used-loading">
                        <i className="icon-spinner"/>
                    </div>
                </div>
            );
        }

        return (
            <div className="frequently-used">

                {(primaryItemsToShow && primaryItemsToShow.length > 0) || (popularFoods && popularFoods.length > 0) ?
                    <div>
                        <h3>Frequently Used</h3>

                        {this.renderTypePicker()}

                        {mode === 'foods' ?
                            <ul className="frequent-list">
                                {primaryItemsToShow.map((meal, i) => {
                                    const food = meal.items[0];
                                    return meal.primary ? this.renderFrequentMeal({items: [food], nutrients: food.nutrients, primary: food.primary}, i) : null;
                                })}
                        {popularFoodsToShow ? popularFoodsToShow.map(this.renderPopularFood) : null}
                            </ul>
                        : null}

                        {mode === 'meals' ?
                            <ul className="frequent-list">
                                {primaryItemsToShow.map((fused, i) => this.renderFrequentMeal(fused, i))}
                            </ul>
                        : null}

                        {mode === 'recipes' ?
                            <ul className="frequent-list">
                                {primaryItemsToShow.map((meal, i) => {
                                    const recipe = meal.items[0];
                                    return this.renderFrequentMeal({items: [recipe], nutrients: recipe.nutrients, primary: recipe.primary}, i);
                                })}
                            </ul>
                        : null}
                        {!showAll && totalItemCount >= numItemsToDisplay ?
                            (<span className="show-more-toggle" onClick={() => this.setState(prevState => ({showAll: !prevState.showAll}))}>
                                <button className="el-medium-btn el-raspberry-btn">Show more <i className="icon-chevron-down"></i></button>
                            </span>)
                        : null}
                    </div>
                : null}
            </div>
        );
    }
}
