'use strict';

import { Component } from 'react';

import './Button.scss'

export default class Button extends Component {
    constructor(props, context) {
        super(props, context);
    }

    render = () => {
        const { title, onClick, children, className, isLoading, disabled, dataTestId } = this.props;

        return (
            <button
                data-testid={dataTestId}
                title={title} onClick={onClick} className={className} disabled={disabled || isLoading}>
                {children} {isLoading ? <i className="feather feather-loader"/> : null}
            </button>
        );
    }
}
