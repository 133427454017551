'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import RecipeEditor from '../../../Recipes/Editor.react';
import SavedRecipes from '../../../Recipes/SavedRecipes.react';

import Analytics from '../../../../utils/Analytics';
import { getConfig } from '../../../../utils/Env';
import AuthStore from '../../../../stores/AuthStore';
import platform from 'platform';

import './UseOwnRecipe.scss';

export default class UseOwnRecipe extends Component {

    static propTypes = {
        profile: PropTypes.object,

        closeModal: PropTypes.func,
    };

    static contextTypes = {
        onSelectRecipe: PropTypes.func,
        isMobile: PropTypes.bool,
        addSwapContext: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            mode: 'create',
        };
    }

    componentDidMount = () => {
        Analytics.startUseOwnRecipe({'Context': 'Add/Swap'});
        this.doesUserHasMultipleRecipes();
    }


    doesUserHasMultipleRecipes = () => {

        const params = {
            types: ['recipe'],
            filters: {
                is_owner: true,
                library: true,
            },
            size: 0,
        };
        
        this.setState({ loading: true });

        AuthStore.fetch(
            getConfig('recipe_api') + '/search',
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json; schema=search/advanced/1' },
                body: JSON.stringify(params),
            },
            true
        ).then(
            (results) => {
                if (results?.total >= 1) {
                    this.setSavedMode();
                }
                this.setState({ loading: false})
            },
            (error) => this.setState({ loading: false })
        );
    };


    onSaveRecipe = (recipe) => {
        const { onSelectRecipe, addSwapContext } = this.context;

        onSelectRecipe(recipe, addSwapContext.participants);
    }

    setCreateMode = () => {
        this.setState({mode: 'create'}, this.resetSwitcherScroll);
    }

    setSavedMode = () => {
        this.setState({mode: 'saved'}, this.resetSwitcherScroll);
    }

    switcher = null

    resetSwitcherScroll = () => {
        if (!this.switcher) {
            return;
        }

        this.switcher.scrollLeft = 0
    }

    realizeModeSwitcher = (el) => {
        if (el) {
            el.scrollLeft = 0;
        }

        this.switcher = el;
    }

    render() {
        const { mode, loading } = this.state;
        const { profile, closeModal } = this.props;
        const { isMobile } = this.context;

        return (
            <div className="add-swap-search-use-own-recipe">
                <header>
                    {!loading ?
                        <div className="toggle-btn-group">
                            <button data-active={mode === 'create'} onClick={this.setCreateMode}>New Recipe</button>
                            <button data-active={mode === 'saved'} onClick={this.setSavedMode}>My Recipes</button>
                        </div>
                    : null}
                </header>

                <div className="mode-switcher" data-transition={!(platform.name === 'Safari' && !isMobile) ? 'true' : 'false'} data-position={mode === 'saved' ? 1 : 0} ref={this.realizeModeSwitcher}>
                    {loading && mode == "create" ? 
                        <div className="loading-container">
                            <i className="icon-spinner2" />
                        </div>
                    : null}
                    {!loading ? 
                        <div className="tile">
                            <RecipeEditor profile={profile}
                                closeModal={closeModal}
                                onSaveRecipe={this.onSaveRecipe}
                                savedOwnRecipeContext="Add/Swap" />
                        </div>
                    : null}

                    {<div className="tile">
                        {mode === 'saved' ? <SavedRecipes /> : null}
                    </div>}
                </div>
            </div>
        );
    }
}
